<template>
  <div class="">
    <md-card md-with-hover>
      <md-ripple>
        <md-card-header>
          <div class="md-title">Matching Insights</div>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label>Current Cutoff (%)</label>
            <md-input v-model="currentCutoff" type="number" disabled></md-input>
          </md-field>
          <md-field>
            <label>Set Cutoff (%) </label>
            <md-input v-model="userCutoff" type="number" ></md-input>
          </md-field>
          <bar-chart
            style="height: 300px;"
            v-if="matchingChartData"
            :chart-data="matchingChartData"
            :options="matchingChartOptions"/>

        </md-card-content>

        <md-progress-bar v-if='query' md-mode="indeterminate"></md-progress-bar>
        <md-card-actions>
          <md-button @click="loadData()">Reload Data</md-button>
<!--          <md-button class="md-primary" @click="testCutoff()">Test cutoff score</md-button>-->
          <md-button class="md-primary" @click="setCutoff()">Set cutoff score</md-button>
          <md-button class="md-accent" @click="applyPotentialMatches()">Apply Matches</md-button>
        </md-card-actions>
      </md-ripple>
    </md-card>
  </div>
</template>

<script>
import BarChart from './charts/bar.vue'
import {axiosGet, axiosPatch, axiosPost} from "@/utils/axiosWrapper";

export default {
  name: 'Matching',
  props: {
  },
  data () {
    return {
      matchingInsights: {
        matchedUsersSupporting: 0,
        matchedUsersSearching: 0,
        matchedUsersMutual: 0,
        unmatchedUsersSupporting: 0,
        unmatchedUsersSearching: 0,
        unmatchedUsersMutual: 0,

        unmatchedUsersSupportingPotential: 0,
        unmatchedUsersSearchingPotential: 0,
        unmatchedUsersMutualPotential: 0,
      },
      currentCutoff: 0,
      userCutoff: '',
      query: false,

      matchingChartData: null,
      matchingChartOptions: null
    }
  },
  methods: {
    loadData: async function() {
      this.matchingChartData = null
      this.query = true
      await this.loadCutoff()
      this.matchingInsights = (await axiosPost('/matching/statistics')).data
      this.fillChart()
      this.query = false
    },
    loadCutoff: async function() {
      this.currentCutoff = Math.round((await axiosGet('/number/name/matchingCutoffScore')).data.value * 100)
      this.userCutoff = this.currentCutoff
    },
    fillOldChart: function (){
      this.matchingChartData = {
        labels: [
          'Supporting',
          'Searching',
          'Mutual',
        ],
        datasets: [
          {
            data: [
              this.matchingInsights.matchedUsersSupporting,
              this.matchingInsights.matchedUsersSearching,
              this.matchingInsights.matchedUsersMutual,
            ],
            label: 'Matched Users',
            backgroundColor: '#4caf50CC',
            borderWidth: 1
          },
          {
            data: [
              this.matchingInsights.unmatchedUsersSupporting,
              this.matchingInsights.unmatchedUsersSearching,
              this.matchingInsights.unmatchedUsersMutual,
            ],
            label: 'Unmatched Users',
            backgroundColor: '#f44336CC',
            borderWidth: 1
          }
        ]
      }
      this.matchingChartOptions = {

        responsive: true,
        maintainAspectRatio: false
      }
    },
    fillChart:function(){
      this.matchingChartData = {
        labels: [
          'Supporting',
          'Searching',
          'Mutual',
        ],
        datasets: [
          {
            data: [
              this.matchingInsights.matchedUsersSupporting,
              this.matchingInsights.matchedUsersSearching,
              this.matchingInsights.matchedUsersMutual,
            ],
            label: 'Matched Users',
            backgroundColor: '#4caf50CC',
            borderWidth: 1
          },
          {
            data: [
              this.matchingInsights.unmatchedUsersSupporting,
              this.matchingInsights.unmatchedUsersSearching,
              this.matchingInsights.unmatchedUsersMutual,
            ],
            label: 'Unmatched Users',
            backgroundColor: '#f44336CC',
            borderWidth: 1
          },
          {
            data: [
              this.matchingInsights.unmatchedUsersSupportingPotential,
              this.matchingInsights.unmatchedUsersSearchingPotential,
              this.matchingInsights.unmatchedUsersMutualPotential,
            ],
            label: 'Potentially Matched Users',
            backgroundColor: '#03a9f4CC',
            borderWidth: 1
          }
        ]
      }

      this.matchingChartOptions = {

        responsive: true,
        maintainAspectRatio: false
      }
    },
    testCutoff: async function () {
      this.query = true
      this.matchingInsights = (await axiosPost(
        '/matching/statistics'
        ,{
          "cutoff": (this.userCutoff/100)
        }
      )).data
      this.fillTestChartData()
      this.query = false
    },
    setCutoff: function(){
      axiosPatch('/number/set/matchingCutoffScore/' + (this.userCutoff/100))
        .then(() => this.loadData())
        .catch(e => console.log(e))
    },
    applyPotentialMatches: function(){
      axiosGet('/matching/usePotential')
        .then(() => this.loadData())
        .catch(e => console.log(e))

    }
  },
  components: {
    BarChart
  },
  mounted () {
    this.loadData();
  }
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
