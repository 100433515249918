<template>
  <matching />
</template>
<script>
import Matching from "@/components/Matching";

export default {
  data(){
    return {
    }
  },
  methods:{
  },
  components:{
    Matching
  }
}
</script>
